import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

// App
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.accessToken;

    return next.handle(request).pipe(catchError(error => {
      console.log("intercept------------------------->");
      console.log(error);
        if(error.status == 401 || error.status == 400 || error.status == 403 || error.status == 404){
           sessionStorage.removeItem('mensajeLogin');
           sessionStorage.setItem('mensajeLogin', error.error.error.toString());
        }

      if ([401, 403].indexOf(error.status) !== -1) {
        if (error.status === 401 && isLoggedIn) {
          this.authenticationService.logout();
        }
      } else {
        
        let mensaje = ""
        if(error.status === 500){
          mensaje = '¡Ocurrio un error en el servidor!'
        }else if(error.status === 404){
          mensaje = '¡Servicio no encontrado'
        }
        this.mostrarAlertaError(mensaje)
        
      }

      const e = error.error.message || error.statusText;
      return throwError(e);
    }));
  }

  public mostrarAlertaError(mensaje){
    swal.fire({
      title: '¡Error!',
      text:  mensaje,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#c4231c',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
    })
  }

}
