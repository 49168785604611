import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

// App
import {AuthenticationService} from '../services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
    const currentUser = this.authenticationService.currentUserValue;
    const expectedRole = route.data.expectedRole;
   
    if (currentUser) {
      
      if (expectedRole && expectedRole.length > 0) {
      
        for (const rol of expectedRole) {
          
          if (rol === currentUser.perfil.tipoUsuario) {
            return true;
          }
        }

        console.log("llego asta aqui");
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }
    console.log("ultimo paso");
    this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
