import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '@environment/environment';
import {Router} from '@angular/router';
//import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public sessionStorageAux: any

  constructor(protected http: HttpClient, private router: Router) { //,public wsService: WebsocketService
   
    if (sessionStorage.getItem('currentUser')) {
      this.sessionStorageAux = sessionStorage.getItem('currentUser')
      this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.sessionStorageAux));
    } else {
     
      this.currentUserSubject = new BehaviorSubject<any>(sessionStorage.getItem('currentUser'));
    }
    console.log(this.currentUserSubject);
    this.currentUser = this.currentUserSubject.asObservable();
    console.log(this.currentUser);
  }

  public get currentUserValue(): any {
    
    if (this.currentUserSubject) {
     
      return this.currentUserSubject.value;
    }
  }

  login(usuario: string, password: string) {
    

    /*return this.http.post<any>(`${environment.apiUrl}/api/login?code=` + code, { username, password }).pipe(map(user => {
      if (user && user.access_token) {
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
      }
    }));*/

    const params = {usuario, password};
    console.log(params);
    //return this.http.post<any>(`${environment.apiUrl}/api/account/login`, params).pipe(map(user => {  
    return this.http.post<any>(`${environment.apiUrl}/api/auth/login`, params).pipe(map(user => {  
      console.log("component login----------->**********");
      console.log(user);
      console.log(user.data);
    if (user.status == true) {
      if (user.data.accessToken) {
        
        sessionStorage.setItem('currentUser', JSON.stringify(user.data));
        this.currentUserSubject.next(user.data);
        //this.wsService.loginWS( user.data.nombre + " " + user.data.aPaterno,user.data.id,user.data.perfil.tipoUsuario )
      }
     } 
    }));
  

  }

  logout() {
    
    sessionStorage.clear();
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
   
  }

  recover(email: string): Observable<any> {
    
    const params = {email};
    return this.http.post(`${environment.apiUrl}/api/auth/forgot-password`, params);
  }
  
  downloadFile(): any {
    return this.http.get('http://localhost:8080/api/usuario/listarTodosGanadores', {responseType: 'blob'});
  }


}

