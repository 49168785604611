import {Component, Inject, OnDestroy} from '@angular/core';
import {LoaderService} from 'src/app/shared/services/loader.service';
import {Subject} from 'rxjs';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-loader',
  template: `
    <div *ngIf="isLoading | async" class="preloader">
      <div class="lds-ripple"><div></div><div></div></div>
    </div>
  `
})
export class LoaderPageComponent implements OnDestroy {
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private loaderService: LoaderService) {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.loaderService.setLoading(true);
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError
        ) {
          this.loaderService.setLoading(false);
        }
      },
      () => {
        this.loaderService.setLoading(false);
      }
    );
  }

  ngOnDestroy(): void {
    this.loaderService.setLoading(false);
  }
}
