import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@shared/helpers/auth.guard';
/*
import { GruposComponent } from '@views/admin/grupos/grupos.component';
import { GraficasComponent } from '@views/admin/graficas/graficas.component';
import { CrearGruposComponent } from '@views/admin/grupos/crear-grupos/crear-grupos.component';
*/
const routes: Routes = [
  {path: '', redirectTo: 'auth', pathMatch: 'full'},
  {path: 'auth', loadChildren: () => import('@views/auth/auth.module').then(m => m.AuthModule)},
  {path: 'admin', loadChildren: () => import('@views/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard]},
  {path: 'capturista', loadChildren: () => import('@views/capturista/capturista.module').then(m => m.CapturistaModule), canActivate: [AuthGuard]},
  {path: 'perfil', loadChildren: () => import('@views/perfil/perfil.module').then(m => m.PerfilModule), canActivate: [AuthGuard]},
  {path: 'agente', loadChildren: () => import('@views/agente/agente.module').then(m => m.AgenteModule), canActivate: [AuthGuard]},
  {path: 'inspector', loadChildren: () => import('@views/inspector/inspector.module').then(m => m.InspectorModule), canActivate: [AuthGuard]},
  {path: '**', redirectTo: '/404'}
];


/*const routes: Routes = [
  { path: '', redirectTo: '/grupos', pathMatch: 'full' },
  { path: 'grupos', component: GruposComponent},
  { path: 'crear-grupos', component: CrearGruposComponent},
  { path: 'graficas', component: GraficasComponent},
    
  ];*/

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
