import { Component,OnInit } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
//import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  //public wsService: WebsocketService
  constructor() {
    //setTheme('bs3');
  }
  
  ngOnInit() {}

}
