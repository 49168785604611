import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from '@shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from '@shared/helpers/error.interceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoaderPageComponent } from '@shared/loader-page/loader.page.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPaginationModule } from 'ngx-pagination';
import { AccordionModule, } from 'ngx-bootstrap/accordion';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverModule, } from 'ngx-bootstrap/popover';
import { TabsModule, } from 'ngx-bootstrap/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DtIconModule } from '@dynatrace/barista-components/icon';
// import {GoogleMapsModule} from '@angular/google-maps';
// import { AgmCoreModule } from '@agm/core';
/*
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
const config: SocketIoConfig = { url: 'http://localhost:5000', options: {} };*/
@NgModule({
    declarations: [
        AppComponent,
        LoaderPageComponent,
    ],
    exports: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        PaginationModule.forRoot(),
        NgxPaginationModule,
        AccordionModule.forRoot(),
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        TabsModule.forRoot(),
        // GoogleMapsModule,
        // AgmCoreModule.forRoot({
        //     apiKey: 'AIzaSyA2zx8NPA9uOB4Rthr8FLR61uvP2PjWQu4'
        // }),
        //SocketIoModule.forRoot(config),
        FormsModule,
        ReactiveFormsModule,
        DtIconModule.forRoot({ svgIconLocation: '/assets/icons/{{name}}.svg' }),
        
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    providers: [BsModalService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
